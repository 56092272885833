import { withScope, captureException as captureExceptionSentry } from '@sentry/browser';

export type Details = {
	[key: string]: string;
};

// structured tags that we send to Sentry
// everything else from details object will go into "extras"
const tags = ['packageName', 'teamName', 'hash', 'userLocation'];
export const captureException = (location: string, error: Error, details?: Details) => {
	if (!error) {
		return;
	}
	withScope((scope) => {
		scope.setTag('logger', location);
		if (details) {
			Object.keys(details).forEach((key) => {
				if (tags.includes(key)) {
					scope.setTag(key, details[key]);
				} else {
					scope.setExtra(key, details[key]);
				}
			});
		}
		captureExceptionSentry(error);
	});
};
