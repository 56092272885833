import { type Details, captureException as captureSentryException } from './sentry-browser.tsx';

// IE11 error with new sentry browser sdk - https://jdog.jira-dev.com/browse/JFP-2406
// remove when IE11 is deprecated and switch to new sentry client
/**
 * Capture an exception and send it to the error reporting system (currently Sentry)
 * @param location - a "analyticsId" of location.
 * @param error - an error object.
 * @param [details] - extra paramiters to pass
 */
export const captureException = (location: string, error: Error, details: Details = {}) =>
	__SERVER__ ? undefined : captureSentryException(location, error, details);
