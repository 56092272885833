import matchRouteOrderIndependent from '@atlassian/match-route';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import matchRouteOrderDependent from '../match-route-order-dependent/index.tsx';
import type { Query, Route, Routes } from '../../types.tsx';
import unSafeMutateRoute from '../unsafe-mutate-route.tsx';
import { matchRouteCache } from './utils.tsx';

const callWhenIdle = (callback: () => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (typeof window.requestIdleCallback === 'function') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.requestIdleCallback(callback);
	} else {
		setTimeout(callback, 3000);
	}
};

const matchRoute = (routes: Routes, pathname: string, queryParams: Query = {}, basePath = '') => {
	const cachedMatch = matchRouteCache.get<Route>(pathname, queryParams, basePath);
	if (cachedMatch && routes.includes(cachedMatch.route)) return cachedMatch;
	let matchedRoute = null;
	try {
		matchedRoute = matchRouteOrderIndependent(routes, pathname, queryParams, basePath);
	} catch (e) {
		log.safeErrorWithoutCustomerData('match_route', 'failed', {
			errorMessage: e instanceof Error ? e.message : 'unknown',
			errorStack: e instanceof Error ? e.stack : 'unknown',
			errorString: typeof e?.toString === 'function' ? e.toString() : 'unknown',
		});
	}
	if (!matchedRoute) return null;
	unSafeMutateRoute(matchedRoute.route);
	matchRouteCache.set(pathname, queryParams, basePath, matchedRoute);

	// Exclude shadow route matching from the experiences that lazy load routes
	// - Issue View loads 3 routes upfront
	// - Issue Navigator loads 2
	// - Sotware Board loads 1
	if (routes.length > 3) {
		callWhenIdle(() => {
			const matchedRouteOld = matchRouteOrderDependent(routes, pathname, queryParams, basePath);
			if (matchedRouteOld) {
				// if route object ref is different, log it
				if (matchedRoute) {
					if (matchedRouteOld.route !== matchedRoute.route) {
						log.safeInfoWithoutCustomerData('match_route', 'diff', {
							newName: matchedRoute.route.name,
							newPath: matchedRoute.route.path,
							newQuery: matchedRoute.route.query?.length || 0,
							oldName: matchedRouteOld.route.name,
							oldPath: matchedRouteOld.route.path,
							oldQuery: matchedRouteOld.route.query?.length || 0,
						});
					}
				} else {
					log.safeInfoWithoutCustomerData('match_route', 'diff', {
						newName: 'legacy-redirect',
						newPath: '',
						newQuery: 0,
						oldName: matchedRouteOld.route.name,
						oldPath: matchedRouteOld.route.path,
						oldQuery: matchedRouteOld.route.query?.length || 0,
					});
				}
			}
		});
	}

	return matchedRoute;
};

export default matchRoute;
